import { Link } from 'react-router-dom';
import Socials from '../components/socials/socials';

import '../css/home.css';

export default function Home() {
    return (
        <section className='page-content-container'>
            <section className="home-welcome">
                <div className="home-welcome-image">
                    <img src="/img/displays/event-display-a.jpg" alt="Welcome to Cattle Dog Creations" />
                </div>
                <div className="home-welcome-description">
                    <p className="home-subtitle">
                        <h2 className="product-header">What can <span>WE</span><br />
                        do for <span>YOU?</span></h2>
                        <p className="ml-4 mt-1"><Link to="/what-we-offer/sublimation" className="button-blue">Explore our designs</Link></p>
                    </p>
                </div>
            </section>

            <section>
                <p className="filler-quote">Our mission is to create beautiful, handmade crafts that bring joy.</p>
                <div className="title-subline mb-3" />
            </section>

            <section className="home-products">
                <div className="home-products-top">&nbsp;</div>
                <div className="home-products-content">
                    <h2 className="page-product-header">CDC in many forms<br /><div className="title-subline" /></h2>
                    <div className="product-highlight">
                        <p className="product">
                            <p className="product-highlight-image">
                                <img src='/img/product-types/sublimation/keychain-sublimation-1.jpg' alt="sublimation-example" />
                            </p>
                            <h3 className="product-title">Sublimation</h3>
                            <p className="product-description">Tumblers, Mugs, Keychains, Earrings, Coasters</p>
                            <Link to="/what-we-offer/sublimation" className="button-blue mt-1">Explore Sublimation</Link>
                        </p>
                        <p className="product">
                            <p className="product-highlight-image">
                                <img src='/img/product-types/laser-designs/laser-cut-designs-1.jpg' alt="Laser Cut/Engrave Example" />
                            </p>
                            <h3 className="product-title">Laser Services</h3>
                            <p className="product-description">Tumblers, Mugs, Keychains, Earrings, Coasters</p>
                            <Link to="/what-we-offer/sublimation" className="button-blue mt-1">Explore Laser</Link>
                        </p>
                        <p className="product">
                            <p className="product-highlight-image">
                                <img src='/img/product-types/cricut-designs/cricut-designs-4.jpg' alt="Cricut Example" />
                            </p>
                            <h3 className="product-title">Cricut</h3>
                            <p className="product-description">Tumblers, Mugs, Keychains, Earrings, Coasters</p>
                            <Link to="/what-we-offer/sublimation" className="button-blue mt-1">Explore Cricut</Link>
                        </p>
                        <p className="product">
                            <p className="product-highlight-image">
                                <img src='/img/product-types/crochet-designs/chillaweens-crochet.jpg' alt="Crochet Example" />
                            </p>
                            <h3 className="product-title">Crochet</h3>
                            <p className="product-description">Tumblers, Mugs, Keychains, Earrings, Coasters</p>
                            <Link to="/what-we-offer/sublimation" className="button-blue md mt-1">Explore Crochet</Link>
                        </p>
                    </div>
                </div>
                <div className="home-products-bottom">&nbsp;</div>
            </section>

            <Socials />
        </section>
    );
}