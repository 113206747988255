import Socials from '../components/socials/socials';

export default function Social() {
    return (
        <>
        <section className="page-content-container">
            <p className="filler-quote">Email Us</p>
            <div className="title-subline mb-3" /><a href="mailto:sales@cattle-dog-creations.com" className="link-large">sales@cattle-dog-creations.com</a>
            <Socials />
        </section>
        </>
    );
}
