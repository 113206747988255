import './socials.css';

export default function Socials() {
    return (
        <section className="socials-container my-2">
            <p className="filler-quote">Find and follow us online</p>
            <div className="title-subline mb-3" />
            
            <div className="social-links">
                <a href="https://www.facebook.com/CattleDogCreation" className="social-link" target="_blank" rel="noreferrer">
                    <img src="/img/social-media-logos/facebook_logo.png" width="150" height="150" alt="Follow Cattle Dog Creations on Facebook" />
                    <strong>Follow us on Facebook</strong>
                </a>

                <a href="https://www.instagram.com/cattledogcreations757/" className="social-link" target="_blank" rel="noreferrer">
                    <img src="/img/social-media-logos/instagram_logo.png" width="150" height="150" alt="Follow Cattle Dog Creations on Instagram" />
                    <strong>Follow us on Instagram</strong>
                </a>

                <a href="https://www.etsy.com/shop/CDCreationsOnline" className="social-link" target="_blank" rel="noreferrer">
                    <img src="/img/social-media-logos/etsy-logo.png" width="150" height="150" alt="Explore our Etsy Store" />
                    <strong>Explore our Etsy Store</strong>
                </a>
            </div>
        </section>
    );
}