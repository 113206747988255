import { Link } from "react-router-dom";

export default function Custom() {
    return (
        <>
        <div></div>
        <div>
            <p><strong>Tailored Creations Just for You:</strong> Embrace the power of personalization with our custom items. Whether you have a specific vision in mind or need a one-of-a-kind gift, our bespoke creations are tailored to meet your unique preferences. From custom-engraved jewelry to personalized home decor and bespoke apparel, we work closely with you to bring your ideas to life. With attention to detail and craftsmanship, each custom item is a reflection of your individual style and makes for a memorable and meaningful keepsake. Experience the joy of owning personalized creations that are as unique as you are.</p>
            <p className="back-button"><Link to="/what-we-offer/">Back to What We Offer</Link></p>
        </div>
        </>
    ) ;
}