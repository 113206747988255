import { Outlet } from 'react-router-dom';
import ProductExamples from '../components/products/product-examples';

export default function WhatWeOffer() {
    return (
        <section className="page-content-container">
            <h1 className="page-header">What We Offer</h1>
            <ProductExamples />
            <Outlet />
        </section>
    );
}