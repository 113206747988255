import { useParams } from "react-router-dom"
import ProductSideNav from "./product-sidenav";

import SublimationProducts from "./product-sections/sublimation";
import Crochet from "./product-sections/crochet";
import DoorWreathsHangers from "./product-sections/door-wreaths-hangers";
import Cricut from "./product-sections/cricut";
import Laser from "./product-sections/laser";
import Custom from "./product-sections/custom";

function getPageContent(product) {
    switch (product) {
        case 'sublimation':
            return <SublimationProducts />;
        case 'crochet':
            return <Crochet />;
        case 'door-hangers-and-wreaths':
            return <DoorWreathsHangers />;
        case 'cricut':
            return <Cricut />;
        case 'laser':
            return <Laser />;
        case 'custom':
            return <Custom />;
        default:
            break;
    }
}

export default function ProductItem() {
    
    const rootProduct = useParams().product;
    const productTitle = rootProduct.replace(/-/g, ' ').replace(/_/g, '-');

    return (
        <section className='page-content-container product-item-container'>
            <ProductSideNav />
            <article className="product-content-container">
                <p><img src={`/img/product-headers/${rootProduct}.png`} alt={productTitle} /></p>
                <div className="product-page-content">
                { getPageContent(rootProduct) }
                </div>
            </article>
        </section>
    );
}