import ImageGallery from "../../image-slider";
import { sublimationImages } from "../../../helpers/image-files";
import { Link } from "react-router-dom";

export default function SublimationProducts() {
    return (
        <> 
            <div>
                <ImageGallery images={sublimationImages} />
                <p className="back-button"><Link to="/what-we-offer/">Back to What We Offer</Link></p>
            </div>
            <div>
                <h2 className="page-product-header">Tumblers</h2>
                <p>Welcome to our online store where we bring you a wide range of customizable sublimation tumblers! Whether you need the perfect personalized gift or want to showcase your own unique style, our tumblers are designed to cater to your specific requirements. With our commitment to quality and attention to detail, you can trust that your custom tumbler will be a true conversation starter.</p>

                <p><strong>Product Options:</strong></p>
                <ol>
                    <li><strong>20oz Tumblers:</strong> Our 20oz tumblers are the perfect companion for any drink. From hot coffee to refreshing iced beverages, these versatile tumblers will keep your drink at the perfect temperature for longer periods of time.</li>
                    <li><strong>12oz Children's Sippy Cup Style Tumblers:</strong> We haven't forgotten about our little ones! Our 12oz sippy cup style tumblers are designed specifically for children. With spill-proof lids and vibrant designs, they are perfect for little hands on the go.</li>
                    <li><strong>Sets of 1 x 20oz Tumbler and 1 x 12oz Sippy Cup:</strong> For a wonderful parent-child bonding experience, we offer sets that include a 20oz tumbler for the adults and a 12oz sippy cup for the little ones. Enjoy your favorite beverages together in style!</li>
                    <li><strong>Sets of Two 20oz Tumblers and a 12oz Children's Sippy Cup:</strong> Double the delight with our sets of two 20oz tumblers and a matching 12oz children's sippy cup. Perfect for couples or best friends who want to share their love for stunning designs.</li>
                </ol>

                <p><strong>Custom Orders and Prints on Demand:</strong></p>
                <p>At our store, we understand that uniqueness matters. That's why we offer the option for custom orders and prints on demand. If you have a specific design in mind or want to incorporate a personal touch, we are here to bring your vision to life. Whether it's a family photo, a company logo, or a special message, our skilled team will ensure that your tumbler becomes a personalized work of art.</p>

                <p><strong>Contact Us:</strong></p>
                <p>To discuss your custom ideas and requirements, please don't hesitate to reach out to us. We love hearing from our customers and are excited to create something extraordinary just for you. Feel free to contact our friendly customer support team via phone or email, and they will guide you through the customization process.</p>
                <p>Invest in a tumbler that reflects your style and personality! With our custom sublimation tumblers, enjoy your favorite beverages while turning heads wherever you go. Shop now and experience the joy of having a tumbler that is uniquely yours!</p>

                <h2 className="page-product-header">Mugs</h2>
                <p>11oz mugs and 15oz mugs</p>

                <h2 className="page-product-header">Keychains</h2>
                <p>The perfect companion to your keys, we have a wide variety of keychain designs and styles to suit any and all tastes.</p>

                <h2 className="page-product-header">Earrings</h2>
                <p>All our drop earings have sterling silver hooks and are sublimated in a variety of design themes.</p>

                <h2 className="page-product-header">Car Coasters</h2>
                <p>Perfect for helping to protect your car`s cup holders from the wear and tear travel mugs subject them to.</p>
            </div>
        </>
    )
}