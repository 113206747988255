import ImageGallery from "../../image-slider";
import { crochetImages } from "../../../helpers/image-files";
import { Link } from "react-router-dom";

export default function Crochet() {
    return (
        <>
            <div>
                <ImageGallery images={crochetImages} />
            </div>
            <div>
                <p><strong>Crochet Plushies:</strong> Our lovingly made crochet plushies are huggable companions designed to bring joy to children and adults alike. From charming animal plushies to whimsical fantasy creatures, each piece is crafted with care and attention to detail, making them perfect for gifting or as treasured keepsakes.</p>
                <p><strong>Custom Crochet Creations</strong>: Please get in touch with us to see what we can do for you.</p>
                <p className="back-button"><Link to="/what-we-offer/">Back to What We Offer</Link></p>
            </div>
        </>
    );
}