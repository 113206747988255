import { useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { BsArrowUpRightSquare, BsFacebook, BsInstagram } from 'react-icons/bs';

import './css/nav-bar.css';

export default function NavBar() {
    const [isOpen, setIsOpen] = useState(false);

    function CustomLink({ to, children, ...props }) {
        const resolvedPath = useResolvedPath(to);
        const isActive = useMatch({path: resolvedPath.pathname, end: true});
    
        function navToggle() {
            setIsOpen(false)
        }
    
        return (
            <li className={isActive ? "active" : ""}>
                <Link to={to} {...props} onClick={() => navToggle()}>
                    {children}
                </Link>
            </li>
        )
    }

    return (
        <div className="nav-container">
            <nav className="nav desktop">
                <div className="logo-socials">
                    <Link to="/" className="site-title">
                        <img src="/img/cattle-dog-creations-bandw-blacktext.png" width="60" height="60" alt="Cattle Dog Creations" aria-describedby="Cattle Dog Creations Logo" />
                        Cattle Dog Creations
                    </Link>
                    <span className="socials">
                        <a 
                            href="https://www.facebook.com/CattleDogCreation"
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                                <BsFacebook />
                        </a>
                        <a 
                            href="https://www.instagram.com/cattledogcreations757/"
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                                <BsInstagram />
                        </a>
                        <a 
                            href="https://www.etsy.com/shop/CDCreationsOnline" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            Etsy Store <BsArrowUpRightSquare className="external-link-icon" title="External Link to Cattle Dog Creations Online Etsy.com" />
                        </a>
                    </span>
                </div>
                <ul>
                    <CustomLink to="/">Home</CustomLink>
                    <CustomLink to="/what-we-offer/sublimation">Sublimation</CustomLink>
                    <CustomLink to="/what-we-offer/laser">Laser Cutting/Engraving</CustomLink>
                    <CustomLink to="/what-we-offer/cricut">Cricut</CustomLink>
                    <CustomLink to="/what-we-offer/crochet">Crochet</CustomLink>
                    <CustomLink to="/about">About Us</CustomLink>
                    <CustomLink to="/contact">Contact Us</CustomLink>
                </ul>
            </nav>
            <nav className="nav mobile">
                <Link to="/" className="site-title" onClick={() => setIsOpen(false)}>
                    <img src="/img/cattle-dog-creations-bandw-blacktext.png" width="60" height="60" alt="Cattle Dog Creations" aria-describedby="Cattle Dog Creations Logo" />
                    Cattle Dog Creations
                </Link>
                <span className="icon" onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? <i className="fa fa-times" aria-hidden="true"></i> : <i className="fa fa-bars"></i>}
                </span>
                {isOpen && <ul>
                    <CustomLink to="/">Home</CustomLink>
                    <CustomLink to="/what-we-offer/sublimation">Sublimation</CustomLink>
                    <CustomLink to="/what-we-offer/laser">Laser Cutting/Engraving</CustomLink>
                    <CustomLink to="/what-we-offer/cricut">Cricut</CustomLink>
                    <CustomLink to="/what-we-offer/crochet">Crochet</CustomLink>
                    <CustomLink to="/about">About Us</CustomLink>
                    <CustomLink to="/contact">Contact Us</CustomLink>
                    <li><a 
                        href="https://www.facebook.com/CattleDogCreation"
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                            <BsFacebook />&nbsp;Facebook
                    </a></li>
                    <li><a 
                        href="https://www.instagram.com/cattledogcreations757/"
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                            <BsInstagram />&nbsp;Instagram
                    </a></li>
                    <li><a 
                        href="https://www.etsy.com/shop/CDCreationsOnline" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Etsy Store&nbsp;<BsArrowUpRightSquare className="external-link-icon" title="External Link to Cattle Dog Creations Online Etsy.com" />
                    </a></li>
                </ul>}
            </nav>
        </div>
    );
};
